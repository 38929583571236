import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { IOption } from '@/features/options/interfaces/option.interface'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/optiontypes`

const fetchOptions = createAsyncThunk(
  'options/fetchOptions',
  async (params: { tableParams: TablePaginationConfig; typeId?: number; search?: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${buildPaginationQuery(baseUrl, params.tableParams)}${params.typeId ? `&type=${params.typeId}` : ''}${
        params.search ? `&search=${params.search}` : ''
      }`,
      {},
    )
    return response.data
  },
)

const fetchOption = createAsyncThunk('options/fetchOption', async (id: number) => {
  const response = await api(HttpMethods.GET, `${baseUrl}/${id}`, {})
  return response.data.item
})

const deleteOption = createAsyncThunk('options/deleteOption', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${baseUrl}/${id}`, {})
  return response.data
})

const updateOption = createAsyncThunk('options/updateOption', async (data: Partial<IOption>) => {
  const response = await api(HttpMethods.PUT, `${baseUrl}/${data.id}`, data)
  return response.data
})

const addOption = createAsyncThunk('options/addOption', async (data: {}) => {
  const response = await api(HttpMethods.POST, baseUrl, data)
  return response.data
})

const optionPriceIntervals = createAsyncThunk('options/fetchOptionPriceIntervals', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/options_price_intervals`)
  return response.data
})

const optionPriceIntervalsForCategory = createAsyncThunk(
  'options/optionPriceIntervalsForCategory',
  async (category_id: number) => {
    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/options_price_intervals/${category_id}`,
    )
    return response.data
  },
)

const saveOptionsOrder = async (data: {}) => {
  const response = await api(HttpMethods.POST, `${baseUrl}/order`, data)
  return response.data
}

export const optionsService = {
  fetchOptions,
  fetchOption,
  deleteOption,
  updateOption,
  addOption,
  saveOptionsOrder,
  optionPriceIntervals,
  optionPriceIntervalsForCategory,
}
