import { createAsyncThunk } from '@reduxjs/toolkit'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'

interface ITaxPut {
  id: number
  data: {
    start_date?: string
    end_date?: string
    value: number
    value_mailbox?: number
    value_storage?: number
    value_refrigerator?: number
    value_locker_half_height?: number
    value_locker_full_height?: number
    value_double_door_cabinets?: number
    value_emr?: number
    value_mobile_storage_cart?: number
    paid_by_customer?: number
  }
}

const fetchSalesTax = createAsyncThunk('taxes/fetchSalesTax', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/taxes/sales-tax`)
  return response.data.original
})

const updateSalesTax = createAsyncThunk('taxes/updateSalesTax', async ({ id, data }: ITaxPut) => {
  try {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/taxes/sales-tax/${id}`,
      data,
    )
    return response.data
  } catch (error) {
    throw error
  }
})

const addSalesTax = createAsyncThunk('taxes/addSalesTax', async (data: any) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/taxes/sales-tax`, data)
    return response.data
  } catch (error) {
    throw error
  }
})

const fetchRentTax = createAsyncThunk('taxes/fetchRentTax', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/taxes/rent-tax`)
  return response.data.original
})

const updateRentTax = createAsyncThunk('taxes/updateRentTax', async ({ id, data }: ITaxPut) => {
  try {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/taxes/rent-tax/${id}`,
      data,
    )
    return response.data
  } catch (error) {
    throw error
  }
})

const addRentTax = createAsyncThunk('taxes/addRentTax', async (data: any) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/taxes/rent-tax`, data)
    return response.data
  } catch (error) {
    throw error
  }
})

const fetchFees = createAsyncThunk('taxes/fetchFees', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/fees/insurance_fee`)
  return response.data.original
})

const fetchDeposit = createAsyncThunk('taxes/fetchDeposit', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/fees/deposit`)
  return response.data.original
})

const updateFee = createAsyncThunk('taxes/updateRentTax', async ({ id, data }: ITaxPut) => {
  try {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/fees/insurance_fee/${id}`,
      data,
    )
    return response.data
  } catch (error) {
    throw error
  }
})

const addFee = createAsyncThunk('taxes/addFee', async (data: any) => {
  try {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/fees/insurance_fee`,
      data,
    )
    return response.data
  } catch (error) {
    throw error
  }
})

const updateDeposit = createAsyncThunk('taxes/updateDeposit', async ({ id, data }: ITaxPut) => {
  try {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/fees/deposit/${id}`,
      data,
    )
    return response.data
  } catch (error) {
    throw error
  }
})

const addDeposit = createAsyncThunk('taxes/addDeposit', async (data: any) => {
  try {
    const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/fees/deposit`, data)
    return response.data
  } catch (error) {
    throw error
  }
})

export const taxService = {
  fetchSalesTax,
  updateSalesTax,
  addSalesTax,
  fetchRentTax,
  updateRentTax,
  addRentTax,
  fetchFees,
  updateFee,
  addFee,
  fetchDeposit,
  updateDeposit,
  addDeposit,
}
