import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

export type NotificationType = {
  id: string
  site_id: number
  user_id: number
  from_user: {
    id: number
    name: string
  }
  data: {
    mark_completed: 0 | 1
    title: string
    body: string
    url: string
  }
  notification_type: string
  read_at: string | null
  created_at: string
}

export type CreateNotificationType = {
  user_id: number
  notification_type: string
  title: string
  body: string
  url: string
  mark_completed: number
}

export interface INotificationsState {
  notifications: IStoreApiList<NotificationType[]>
  unreadNotifications: IStoreApiList<NotificationType[]>
  readNotifications: IStoreApiList<NotificationType[]>
  createNotification: IStoreApiItem<NotificationType>
  markAsRead: IStoreApiItem<{ notification: NotificationType }>
  markAllAsRead: IStoreApiItem<{ message: string }>
}

export class NotificationsState implements INotificationsState {
  notifications: IStoreApiList<NotificationType[]> = new StoreApiList()
  unreadNotifications: IStoreApiList<NotificationType[]> = new StoreApiList()
  readNotifications: IStoreApiList<NotificationType[]> = new StoreApiList()
  createNotification: IStoreApiItem<NotificationType> = new StoreApiItem()
  markAsRead: IStoreApiItem<{ notification: NotificationType }> = new StoreApiItem()
  markAllAsRead: IStoreApiItem<{ message: string }> = new StoreApiItem()
}
