import { createSlice } from '@reduxjs/toolkit'

import {
  errorItemData,
  errorListData,
  receiveItemData,
  receiveListData,
  requestItemData,
  requestListData,
} from '@/common/utils/state.utils'

import {
  IDoctorLeaseRealTime,
  IDoctorLocks,
  ILock,
  IDoors,
  IIPCamera,
  ILogs,
  ISiteStatistics,
  ITvsAndTablets,
  ICabinets,
  IMedicalCarts,
  IMobileMedicalCarts,
  IDepositRefund,
} from 'src/features/home/interfaces/home.interfaces'

import { homeService } from './home.service'
import { HomeState } from './home.state.interface'

const initialState = new HomeState()

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(homeService.fetchDoctorsLeasesRealTime.pending, (state) => {
        return requestListData<HomeState, IDoctorLeaseRealTime[]>({ ...state }, ['doctorsLeasesRealTime'])
      })
      .addCase(homeService.fetchDoctorsLeasesRealTime.fulfilled, (state, action) => {
        return receiveListData<HomeState, IDoctorLeaseRealTime[]>(
          { ...state },
          ['doctorsLeasesRealTime'],
          action.payload,
        )
      })
      .addCase(homeService.fetchDoctorsLeasesRealTime.rejected, (state, action) => {
        return errorListData<HomeState, IDoctorLeaseRealTime[]>(
          { ...state },
          ['doctorsLeasesRealTime'],
          String(action.error.message),
        )
      })

      /// statistics

      .addCase(homeService.fetchSiteStatistics.pending, (state) => {
        return requestListData<HomeState, ISiteStatistics[]>({ ...state }, ['siteStatistics'])
      })
      .addCase(homeService.fetchSiteStatistics.fulfilled, (state, action) => {
        const { site_statistics } = action.payload
        return receiveListData<HomeState, ISiteStatistics[]>({ ...state }, ['siteStatistics'], site_statistics)
      })
      .addCase(homeService.fetchSiteStatistics.rejected, (state, action) => {
        return errorListData<HomeState, ISiteStatistics[]>(
          { ...state },
          ['siteStatistics'],
          String(action.error.message),
        )
      })

      /// deposit refunds

      .addCase(homeService.fetchDepositRefunds.pending, (state) => {
        return requestListData<HomeState, IDepositRefund[]>({ ...state }, ['depositRefunds'])
      })

      .addCase(homeService.fetchDepositRefunds.fulfilled, (state, action) => {
        return receiveListData<HomeState, IDepositRefund[]>({ ...state }, ['depositRefunds'], action.payload)
      })

      .addCase(homeService.fetchDepositRefunds.rejected, (state, action) => {
        return errorListData<HomeState, IDepositRefund[]>(
          { ...state },
          ['depositRefunds'],
          String(action.error.message),
        )
      })

      .addCase(homeService.processDepositRefunds.pending, (state) => {
        return requestListData<HomeState, IDepositRefund[]>({ ...state }, ['processDepositRefunds'])
      })

      .addCase(homeService.processDepositRefunds.fulfilled, (state, action) => {
        return receiveListData<HomeState, IDepositRefund[]>({ ...state }, ['processDepositRefunds'], action.payload)
      })

      .addCase(homeService.processDepositRefunds.rejected, (state, action) => {
        return errorListData<HomeState, IDepositRefund[]>(
          { ...state },
          ['processDepositRefunds'],
          String(action.error.message),
        )
      })

      /// ip cameras

      .addCase(homeService.fetchIpCameras.pending, (state) => {
        return requestListData<HomeState, IIPCamera[]>({ ...state }, ['ipCameras'])
      })
      .addCase(homeService.fetchIpCameras.fulfilled, (state, action) => {
        const { total_items, ip_cameras = [] } = action.payload
        return receiveListData<HomeState, IIPCamera[]>({ ...state }, ['ipCameras'], ip_cameras, total_items)
      })
      .addCase(homeService.fetchIpCameras.rejected, (state, action) => {
        return errorListData<HomeState, IIPCamera[]>({ ...state }, ['ipCameras'], String(action.error.message))
      })

      .addCase(homeService.rotateAllCameras.pending, (state) => {
        return requestListData<HomeState, IIPCamera[]>({ ...state }, ['ipCamerasRotate'])
      })
      .addCase(homeService.rotateAllCameras.fulfilled, (state, action) => {
        const { total_items, ip_cameras = [] } = action.payload
        return receiveListData<HomeState, IIPCamera[]>({ ...state }, ['ipCamerasRotate'], ip_cameras, total_items)
      })
      .addCase(homeService.rotateAllCameras.rejected, (state, action) => {
        return errorListData<HomeState, IIPCamera[]>({ ...state }, ['ipCamerasRotate'], String(action.error.message))
      })

      /// doors

      .addCase(homeService.fetchDoors.pending, (state) => {
        return requestListData<HomeState, IDoors>({ ...state }, ['doors'])
      })
      .addCase(homeService.fetchDoors.fulfilled, (state, action) => {
        const {
          total_items,
          door_locks = [],
          device_state_type_code = [],
          device_status_type_code = [],
        } = action.payload
        return receiveListData<HomeState, IDoors>(
          { ...state },
          ['doors'],
          { door_locks, device_state_type_code, device_status_type_code },
          total_items,
        )
      })
      .addCase(homeService.fetchDoors.rejected, (state, action) => {
        return errorListData<HomeState, IDoors>({ ...state }, ['doors'], String(action.error.message))
      })

      .addCase(homeService.toggleAllDoors.pending, (state) => {
        return requestListData<HomeState, ILock[]>({ ...state }, ['openAllDoors'])
      })
      .addCase(homeService.toggleAllDoors.fulfilled, (state, action) => {
        const { total_items, door_locks = [] } = action.payload
        return receiveListData<HomeState, ILock[]>({ ...state }, ['openAllDoors'], door_locks, total_items)
      })
      .addCase(homeService.toggleAllDoors.rejected, (state, action) => {
        return errorListData<HomeState, ILock[]>({ ...state }, ['openAllDoors'], String(action.error.message))
      })

      /// mailboxes

      .addCase(homeService.fetchMailboxes.pending, (state) => {
        return requestListData<HomeState, IDoors>({ ...state }, ['mailboxes'])
      })
      .addCase(homeService.fetchMailboxes.fulfilled, (state, action) => {
        const {
          total_items,
          door_locks = [],
          device_state_type_code = [],
          device_status_type_code = [],
        } = action.payload
        return receiveListData<HomeState, IDoors>(
          { ...state },
          ['mailboxes'],
          { door_locks, device_state_type_code, device_status_type_code },
          total_items,
        )
      })
      .addCase(homeService.fetchMailboxes.rejected, (state, action) => {
        return errorListData<HomeState, IDoors>({ ...state }, ['mailboxes'], String(action.error.message))
      })

      .addCase(homeService.toggleAllMailboxes.pending, (state) => {
        return requestListData<HomeState, ILock[]>({ ...state }, ['openAllMailboxes'])
      })
      .addCase(homeService.toggleAllMailboxes.fulfilled, (state, action) => {
        const { total_items, door_locks = [] } = action.payload
        return receiveListData<HomeState, ILock[]>({ ...state }, ['openAllMailboxes'], door_locks, total_items)
      })
      .addCase(homeService.toggleAllMailboxes.rejected, (state, action) => {
        return errorListData<HomeState, ILock[]>({ ...state }, ['openAllMailboxes'], String(action.error.message))
      })
      /// DOCTORS MAILBOX LOCKS

      .addCase(homeService.fetchDoctorsLocks.pending, (state) => {
        return requestListData<HomeState, IDoctorLocks[]>({ ...state }, ['doctorsLocks'])
      })
      .addCase(homeService.fetchDoctorsLocks.fulfilled, (state, action) => {
        return receiveListData<HomeState, IDoctorLocks[]>({ ...state }, ['doctorsLocks'], action.payload.doctors)
      })
      .addCase(homeService.fetchDoctorsLocks.rejected, (state, action) => {
        return errorListData<HomeState, IDoctorLocks[]>({ ...state }, ['doctorsLocks'], String(action.error.message))
      })

      /// TVS AND TABLETS
      .addCase(homeService.fetchTvsAndTablets.pending, (state) => {
        return requestItemData<HomeState, ITvsAndTablets>({ ...state }, ['tvsAndTablets'])
      })
      .addCase(homeService.fetchTvsAndTablets.fulfilled, (state, action) => {
        return receiveItemData<HomeState, ITvsAndTablets>({ ...state }, ['tvsAndTablets'], action.payload)
      })
      .addCase(homeService.fetchTvsAndTablets.rejected, (state, action) => {
        return errorItemData<HomeState, ITvsAndTablets>({ ...state }, ['tvsAndTablets'], String(action.error.message))
      })

      /// LOGS
      .addCase(homeService.fetchLogs.pending, (state) => {
        return requestListData<HomeState, ILogs>({ ...state }, ['logs'])
      })
      .addCase(homeService.fetchLogs.fulfilled, (state, action) => {
        const { total_items, ...rest } = action.payload
        return receiveListData<HomeState, ILogs>({ ...state }, ['logs'], rest, total_items)
      })
      .addCase(homeService.fetchLogs.rejected, (state, action) => {
        return errorListData<HomeState, ILogs>({ ...state }, ['logs'], String(action.error.message))
      })

      /// LOCKERS

      .addCase(homeService.fetchLockers.pending, (state) => {
        return requestListData<HomeState, IDoctorLocks[]>({ ...state }, ['lockers'])
      })
      .addCase(homeService.fetchLockers.fulfilled, (state, action) => {
        return receiveListData<HomeState, IDoctorLocks[]>({ ...state }, ['lockers'], action.payload)
      })
      .addCase(homeService.fetchLockers.rejected, (state, action) => {
        return errorListData<HomeState, IDoctorLocks[]>({ ...state }, ['lockers'], String(action.error.message))
      })

      .addCase(homeService.toggleAllLockers.pending, (state) => {
        return requestListData<HomeState, ILock[]>({ ...state }, ['openAllLockers'])
      })
      .addCase(homeService.toggleAllLockers.fulfilled, (state, action) => {
        const { total_items, door_locks = [] } = action.payload
        return receiveListData<HomeState, ILock[]>({ ...state }, ['openAllLockers'], door_locks, total_items)
      })
      .addCase(homeService.toggleAllLockers.rejected, (state, action) => {
        return errorListData<HomeState, ILock[]>({ ...state }, ['openAllLockers'], String(action.error.message))
      })

      /// CABINETS

      .addCase(homeService.fetchCabinets.pending, (state) => {
        return requestItemData<HomeState, ICabinets>({ ...state }, ['cabinets'])
      })
      .addCase(homeService.fetchCabinets.fulfilled, (state, action) => {
        return receiveItemData<HomeState, ICabinets>({ ...state }, ['cabinets'], action.payload)
      })
      .addCase(homeService.fetchCabinets.rejected, (state, action) => {
        return errorItemData<HomeState, ICabinets>({ ...state }, ['cabinets'], String(action.error.message))
      })

      .addCase(homeService.toggleAllCabinets.pending, (state) => {
        return requestItemData<HomeState, ICabinets>({ ...state }, ['openAllCabinets'])
      })
      .addCase(homeService.toggleAllCabinets.fulfilled, (state, action) => {
        return receiveItemData<HomeState, ICabinets>({ ...state }, ['openAllCabinets'], action.payload)
      })
      .addCase(homeService.toggleAllCabinets.rejected, (state, action) => {
        return errorItemData<HomeState, ICabinets>({ ...state }, ['openAllCabinets'], String(action.error.message))
      })

      /// FRIDGES

      .addCase(homeService.fetchFridges.pending, (state) => {
        return requestItemData<HomeState, ICabinets>({ ...state }, ['fridges'])
      })
      .addCase(homeService.fetchFridges.fulfilled, (state, action) => {
        return receiveItemData<HomeState, ICabinets>({ ...state }, ['fridges'], action.payload)
      })
      .addCase(homeService.fetchFridges.rejected, (state, action) => {
        return errorItemData<HomeState, ICabinets>({ ...state }, ['fridges'], String(action.error.message))
      })

      .addCase(homeService.toggleAllFridges.pending, (state) => {
        return requestItemData<HomeState, ICabinets>({ ...state }, ['openAllFridges'])
      })
      .addCase(homeService.toggleAllFridges.fulfilled, (state, action) => {
        return receiveItemData<HomeState, ICabinets>({ ...state }, ['openAllFridges'], action.payload)
      })
      .addCase(homeService.toggleAllFridges.rejected, (state, action) => {
        return errorItemData<HomeState, ICabinets>({ ...state }, ['openAllFridges'], String(action.error.message))
      })

      /// MEDICAL CARTS

      .addCase(homeService.fetchMedicalCartDocks.pending, (state) => {
        return requestItemData<HomeState, IMedicalCarts>({ ...state }, ['medicalCarts'])
      })
      .addCase(homeService.fetchMedicalCartDocks.fulfilled, (state, action) => {
        return receiveItemData<HomeState, IMedicalCarts>({ ...state }, ['medicalCarts'], action.payload)
      })
      .addCase(homeService.fetchMedicalCartDocks.rejected, (state, action) => {
        return errorItemData<HomeState, IMedicalCarts>({ ...state }, ['medicalCarts'], String(action.error.message))
      })

      .addCase(homeService.toggleAllMedicalCarts.pending, (state) => {
        return requestItemData<HomeState, IMedicalCarts>({ ...state }, ['openAllMedicalCarts'])
      })
      .addCase(homeService.toggleAllMedicalCarts.fulfilled, (state, action) => {
        return receiveItemData<HomeState, IMedicalCarts>({ ...state }, ['openAllMedicalCarts'], action.payload)
      })
      .addCase(homeService.toggleAllMedicalCarts.rejected, (state, action) => {
        return errorItemData<HomeState, IMedicalCarts>(
          { ...state },
          ['openAllMedicalCarts'],
          String(action.error.message),
        )
      })
      // mobile medical carts
      .addCase(homeService.fetchMobileCarts.pending, (state) => {
        return requestItemData<HomeState, IMobileMedicalCarts>({ ...state }, ['mobileMedicalCarts'])
      })
      .addCase(homeService.fetchMobileCarts.fulfilled, (state, action) => {
        return receiveItemData<HomeState, IMobileMedicalCarts>({ ...state }, ['mobileMedicalCarts'], action.payload)
      })
      .addCase(homeService.fetchMobileCarts.rejected, (state, action) => {
        return errorItemData<HomeState, IMobileMedicalCarts>(
          { ...state },
          ['mobileMedicalCarts'],
          String(action.error.message),
        )
      })
  },
})

export default homeSlice.reducer
