import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import { TypeCodes } from '@/common/constants/site-selector.constants'
import api from '@/common/helpers/api.helpers'

import { IRoomNewHardware } from '@/features/sites/interfaces/hardware.interface'
import { IRoom, ISlotChanges } from '@/features/sites/interfaces/site.interface'
import { IDuplicateHardware } from 'src/features/sites/components/site-rooms/pages/duplicate-hardware'

const fetchSiteRooms = createAsyncThunk('rooms/fetchSiteRooms', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms`, {})
  return response.data
})

const fetchAllHardwares = createAsyncThunk('rooms/fetchAllHardwares', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/all-hardware`)
  return response.data
})

const fetchSiteRoom = createAsyncThunk('rooms/fetchSiteRoom', async (id: number) => {
  if (Number(id)) {
    const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms/${id}`, {})
    return response.data
  } else {
    return {}
  }
})

const updateRoom = createAsyncThunk('rooms/updateRoom', async (data: IRoom) => {
  const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms/${data.id}`, data)
  return response.data
})

const fetchSiteRoomTypes = createAsyncThunk('rooms/fetchSiteRoomTypes', async () => {
  const response = await api(
    HttpMethods.GET,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/templates/` + TypeCodes.ROOM_TYPE_CLASS_CODE,
    {},
  )
  return response.data
})

const fetchSiteRoomHardware = createAsyncThunk('rooms/fetchSiteRoomHardware', async (id: number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms/${id}/hardware`, {})
  return response.data
})

const addSiteRoom = createAsyncThunk('rooms/addSiteRoom', async (data: {}) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms`, data)
  return response.data
})

const deleteRoomSite = createAsyncThunk('rooms/deleteRoomSite', async (id: number) => {
  const response = await api(HttpMethods.DELETE, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms/${id}`, {})
  return response.data
})

const createRoomHardware = createAsyncThunk(
  'root/createRoomHardware',
  async (data: { id: number; hardware: IRoomNewHardware }) => {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms/${data.id}/hardware`,
      data.hardware,
    )
    return response.data
  },
)

const updateRoomHardware = createAsyncThunk(
  'root/updateRoomHardware',
  async (data: { roomId: number; hardwareId: number; hardware: IRoomNewHardware }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms/${data.roomId}/hardware/${data.hardwareId}`,
      data.hardware,
    )
    return response.data
  },
)

const deleteRoomHardware = createAsyncThunk('root/deleteRoomHardware', async (ids: number[]) => {
  const response = await api(HttpMethods.DELETE, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/hardware`, {
    ids,
  })
  return response.data
})

const fetchSiteRoomsLeases = createAsyncThunk('rooms/fetchRoomsLeases', async (date: string) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms-leases`, { date })
  return response.data
})

const changeRoomsLeases = createAsyncThunk('rooms/changeRoomsLeases', async (data: ISlotChanges) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms-leases`, data)
  return response.data
})

const fetchRoomsWithOptions = createAsyncThunk('rooms/fetchRoomsWithOptions', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms-with-options`, {
    date: dayjs().format('YYYY-MM-DD'),
  })
  return response.data
})

const duplicateRoom = createAsyncThunk('sites/duplicateRoom', async (data: { id: number; name: string }) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/duplicate-room`, data)
  return response.data
})

const updateDeliverRoomOptions = createAsyncThunk(
  'rooms/updateDeliverRoomOptions',
  async (data: { order_item_id: number; order_item_value: 0 | 1 }) => {
    const response = await api(
      HttpMethods.PUT,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/deliver-room-options`,
      data,
    )
    return response.data
  },
)

const duplicateHardware = createAsyncThunk('sites/duplicateHardware', async (data: IDuplicateHardware) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/duplicate-hardware`, data)
  return response.data
})

export const siteRoomsService = {
  fetchSiteRooms,
  changeRoomsLeases,
  fetchSiteRoom,
  updateRoom,
  fetchSiteRoomTypes,
  addSiteRoom,
  deleteRoomSite,
  updateRoomHardware,
  fetchSiteRoomHardware,
  createRoomHardware,
  deleteRoomHardware,
  fetchSiteRoomsLeases,
  duplicateRoom,
  duplicateHardware,
  fetchRoomsWithOptions,
  updateDeliverRoomOptions,
  fetchAllHardwares,
}
