import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { INotification } from '@/common/interfaces/notification.interface'
import {
  errorListData,
  errorPostPutItemData,
  receiveListData,
  receivePostPutItemData,
  requestListData,
  requestPostPutItemData,
} from '@/common/utils/state.utils'

import { notificationsService } from './notifications.service'
import {
  CreateNotificationType,
  INotificationsState,
  NotificationsState,
  NotificationType,
} from './notifications.state.interface'

const initialState = new NotificationsState()

type NotificationListKeys = 'notifications' | 'unreadNotifications' | 'readNotifications'

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    markNotificationAsReadLocally: (state, action: PayloadAction<string>) => {
      const notificationId = action.payload
      const listKeys: NotificationListKeys[] = ['notifications', 'unreadNotifications', 'readNotifications']

      listKeys.forEach((listKey) => {
        if (state[listKey].data) {
          state[listKey].data = state[listKey].data.map((notification: NotificationType) =>
            notification.id === notificationId ? { ...notification, read_at: new Date().toISOString() } : notification,
          )
        }
      })
      if (state.unreadNotifications.total_items) {
        state.unreadNotifications.total_items = state.unreadNotifications.total_items - 1
      }
    },
    incrementUnreadNotifications: (state) => {
      if (state.unreadNotifications.total_items) {
        state.unreadNotifications.total_items = state.unreadNotifications.total_items + 1
      }
    },
    markAllNotificationsAsReadLocally: (state) => {
      const listKeys: NotificationListKeys[] = ['notifications', 'unreadNotifications', 'readNotifications']
      const currentTime = new Date().toISOString()

      listKeys.forEach((listKey) => {
        if (state[listKey].data) {
          state[listKey].data = state[listKey].data.map((notification: NotificationType) => ({
            ...notification,
            read_at: notification.read_at || currentTime,
          }))
        }
      })
      if (state.unreadNotifications.total_items) {
        state.unreadNotifications.total_items = 0
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(notificationsService.getNotifications.pending, (state) => {
        return requestListData<INotificationsState, INotification[]>({ ...state }, ['notifications'])
      })
      .addCase(notificationsService.getNotifications.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<INotificationsState, NotificationType[]>(
          {
            ...state,
          },
          ['notifications'],
          items,
          total_items,
        )
      })
      .addCase(notificationsService.getNotifications.rejected, (state, action) => {
        return errorListData<INotificationsState, NotificationType[]>(
          {
            ...state,
          },
          ['notifications'],
          String(action.error.message),
        )
      })
      .addCase(notificationsService.getUnreadNotifications.pending, (state) => {
        return requestListData<INotificationsState, INotification[]>({ ...state }, ['unreadNotifications'])
      })
      .addCase(notificationsService.getUnreadNotifications.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<INotificationsState, NotificationType[]>(
          {
            ...state,
          },
          ['unreadNotifications'],
          items,
          total_items,
        )
      })
      .addCase(notificationsService.getUnreadNotifications.rejected, (state, action) => {
        return errorListData<INotificationsState, NotificationType[]>(
          {
            ...state,
          },
          ['unreadNotifications'],
          String(action.error.message),
        )
      })
      .addCase(notificationsService.getReadNotifications.pending, (state) => {
        return requestListData<INotificationsState, INotification[]>({ ...state }, ['readNotifications'])
      })
      .addCase(notificationsService.getReadNotifications.fulfilled, (state, action) => {
        const { items, total_items } = action.payload
        return receiveListData<INotificationsState, NotificationType[]>(
          {
            ...state,
          },
          ['readNotifications'],
          items,
          total_items,
        )
      })
      .addCase(notificationsService.getReadNotifications.rejected, (state, action) => {
        return errorListData<INotificationsState, NotificationType[]>(
          {
            ...state,
          },
          ['readNotifications'],
          String(action.error.message),
        )
      })
      .addCase(notificationsService.createNotification.pending, (state) => {
        return requestPostPutItemData<INotificationsState, CreateNotificationType>({ ...state }, ['createNotification'])
      })
      .addCase(notificationsService.createNotification.fulfilled, (state, action) => {
        return receivePostPutItemData<INotificationsState, CreateNotificationType>(
          {
            ...state,
          },
          ['createNotification'],
          action.payload,
        )
      })
      .addCase(notificationsService.createNotification.rejected, (state, action) => {
        return errorPostPutItemData<INotificationsState, CreateNotificationType>(
          {
            ...state,
          },
          ['createNotification'],
          String(action.error.message),
        )
      })
      .addCase(notificationsService.markAllAsRead.pending, (state) => {
        return requestPostPutItemData<INotificationsState, NotificationType>({ ...state }, ['markAllAsRead'])
      })
      .addCase(notificationsService.markAllAsRead.fulfilled, (state, action) => {
        return receivePostPutItemData<INotificationsState, NotificationType>(
          {
            ...state,
          },
          ['markAllAsRead'],
          action.payload,
        )
      })
      .addCase(notificationsService.markAllAsRead.rejected, (state, action) => {
        return errorPostPutItemData<INotificationsState, NotificationType>(
          {
            ...state,
          },
          ['markAllAsRead'],
          String(action.error.message),
        )
      })
      .addCase(notificationsService.markAsRead.pending, (state) => {
        return requestPostPutItemData<INotificationsState, NotificationType>({ ...state }, ['markAsRead'])
      })
      .addCase(notificationsService.markAsRead.fulfilled, (state, action) => {
        return receivePostPutItemData<INotificationsState, NotificationType>(
          {
            ...state,
          },
          ['markAsRead'],
          action.payload,
        )
      })
      .addCase(notificationsService.markAsRead.rejected, (state, action) => {
        return errorPostPutItemData<INotificationsState, NotificationType>(
          {
            ...state,
          },
          ['markAsRead'],
          String(action.error.message),
        )
      })
  },
})

export const { markNotificationAsReadLocally, markAllNotificationsAsReadLocally, incrementUnreadNotifications } =
  notificationsSlice.actions
export default notificationsSlice.reducer
