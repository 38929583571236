import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { buildPaginationQuery } from '@/common/utils/table.utils'

import { CreateNotificationType } from './notifications.state.interface'

const baseUrl = `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/notifications`

const getNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (tableParams: TablePaginationConfig) => {
    const response = await api(HttpMethods.GET, buildPaginationQuery(baseUrl, tableParams))
    return response.data
  },
)

const getUnreadNotifications = createAsyncThunk(
  'notifications/fetchUnreadNotifications',
  async (tableParams: TablePaginationConfig) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${baseUrl}/get-all-unread-notifications`, tableParams),
    )
    return response.data
  },
)
const getReadNotifications = createAsyncThunk(
  'notifications/fetchReadNotifications',
  async (tableParams: TablePaginationConfig) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${baseUrl}/get-all-read-notifications`, tableParams),
    )
    return response.data
  },
)

const markAsRead = createAsyncThunk('notifications/markAsRead', async (id: string) => {
  const response = await api(HttpMethods.GET, `${baseUrl}/${id}`)
  return response.data
})

const markAllAsRead = createAsyncThunk('notifications/markAllAsRead', async () => {
  const response = await api(HttpMethods.PUT, `${baseUrl}/mark-all-as-read`)
  return response.data
})

const createNotification = createAsyncThunk(
  'notifications/createNotification',
  async (data: CreateNotificationType) => {
    const response = await api(HttpMethods.POST, baseUrl, data)
    return response.data
  },
)

export const notificationsService = {
  getNotifications,
  getUnreadNotifications,
  getReadNotifications,
  markAsRead,
  markAllAsRead,
  createNotification,
}
