import type { ReactNode } from 'react'
import { createContext, useCallback, useContext } from 'react'

import { notification as antNotification } from 'antd'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import closeIcon from '@/common/assets/icons/close-notification.svg'
import errorIcon from '@/common/assets/icons/info-white.svg'
import successIcon from '@/common/assets/icons/success-notification.svg'

import { AltConstants } from '@/common/constants/alt.constants'
import { NotificationType } from '@/common/constants/notification.constants'
import { INotification } from '@/common/interfaces/notification.interface'
import { capitalizeFirstLetter } from '@/common/services/util-services'

interface INotificationProps {
  notification: JSX.Element
  setNotification: (data: INotification) => void
}

const NotificationContext = createContext<INotificationProps | null>(null)

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [api, notification] = antNotification.useNotification()

  const setNotification = useCallback(({ title, description, type }: INotification) => {
    const key: string = nanoid()
    api.success({
      key,
      message: title,
      description: description,
      placement: 'bottomLeft',
      duration: 7,
      className: classNames('notification', `notification${capitalizeFirstLetter(type)}`),
      icon: <img alt={AltConstants.INFO_ICON} src={type === NotificationType.success ? successIcon : errorIcon} />,
      closeIcon: <img src={closeIcon} alt={AltConstants.ACTION_ICON} />,
    })
  }, [])

  return (
    <NotificationContext.Provider value={{ setNotification, notification: notification }}>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  return useContext(NotificationContext) as INotificationProps
}
