import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import {
  IDoctorLeaseRealTime,
  IDoctorLocks,
  ILock,
  IDoors,
  IIPCamera,
  ILockers,
  ILogs,
  ISiteStatistics,
  ITvsAndTablets,
  ICabinets,
  IFridges,
  IMedicalCarts,
  IMobileMedicalCarts,
  IDepositRefund,
} from 'src/features/home/interfaces/home.interfaces'

export interface IHomeState {
  doctorsLeasesRealTime: IStoreApiList<IDoctorLeaseRealTime[]>
  siteStatistics: IStoreApiList<ISiteStatistics[]>
  ipCameras: IStoreApiList<IIPCamera[]>
  ipCamerasRotate: IStoreApiList<IIPCamera[]>
  doors: IStoreApiList<IDoors>
  openAllDoors: IStoreApiList<ILock[]>
  mailboxes: IStoreApiList<IDoors>
  openAllMailboxes: IStoreApiList<ILock[]>
  tvsAndTablets: IStoreApiItem<ITvsAndTablets>
  logs: IStoreApiList<ILogs>
  doctorsLocks: IStoreApiItem<IDoctorLocks[]>
  lockers: IStoreApiItem<ILockers>
  openAllLockers: IStoreApiList<ILockers>
  cabinets: IStoreApiItem<ICabinets>
  openAllCabinets: IStoreApiItem<ICabinets>
  fridges: IStoreApiItem<IFridges>
  openAllFridges: IStoreApiItem<IFridges>
  medicalCarts: IStoreApiItem<IMedicalCarts>
  openAllMedicalCarts: IStoreApiItem<IMedicalCarts>
  mobileMedicalCarts: IStoreApiItem<IMobileMedicalCarts>
  depositRefunds: IStoreApiList<IDepositRefund[]>
  processDepositRefunds: IStoreApiList<IDepositRefund[]>
}

export class HomeState implements IHomeState {
  doctorsLeasesRealTime: IStoreApiList<IDoctorLeaseRealTime[]> = new StoreApiList()
  siteStatistics: IStoreApiList<ISiteStatistics[]> = new StoreApiList()
  ipCameras: IStoreApiList<IIPCamera[]> = new StoreApiList()
  ipCamerasRotate: IStoreApiList<IIPCamera[]> = new StoreApiList()
  doors: IStoreApiList<IDoors> = new StoreApiList()
  openAllDoors: IStoreApiList<ILock[]> = new StoreApiList()
  mailboxes: IStoreApiList<IDoors> = new StoreApiList()
  openAllMailboxes: IStoreApiList<ILock[]> = new StoreApiList()
  tvsAndTablets: IStoreApiItem<ITvsAndTablets> = new StoreApiList()
  logs: IStoreApiList<ILogs> = new StoreApiList()
  doctorsLocks: IStoreApiItem<IDoctorLocks[]> = new StoreApiItem()
  lockers: IStoreApiItem<ILockers> = new StoreApiItem()
  openAllLockers: IStoreApiList<ILockers> = new StoreApiItem()
  cabinets: IStoreApiItem<ICabinets> = new StoreApiItem()
  openAllCabinets: IStoreApiItem<ICabinets> = new StoreApiItem()
  fridges: IStoreApiItem<IFridges> = new StoreApiItem()
  openAllFridges: IStoreApiItem<IFridges> = new StoreApiItem()
  medicalCarts: IStoreApiItem<IMedicalCarts> = new StoreApiItem()
  openAllMedicalCarts: IStoreApiItem<IMedicalCarts> = new StoreApiItem()
  mobileMedicalCarts: IStoreApiItem<IMobileMedicalCarts> = new StoreApiItem()
  depositRefunds: IStoreApiList<IDepositRefund[]> = new StoreApiList()
  processDepositRefunds: IStoreApiList<IDepositRefund[]> = new StoreApiList()
}
