import { RcFile } from 'antd/lib/upload'
import dayjs, { Dayjs } from 'dayjs'

import { DaysOfWeek } from '@/common/constants/custom-prepopulate-items'
import { sidebarMenuLabels } from '@/common/constants/sidebar.constants'
import { IMAGE_UPLOAD_FILE_TYPES } from 'src/common/constants/upload.constants'

import { Hours } from '@/features/sites/constants/site-building-schedule'
import { IBuildingHour } from '@/features/sites/interfaces/site-building-schedule.interface'

import { ServiceSVG } from './serviceSVG'

export const capitalizeFirstLetter = (str: string) => `${str?.charAt(0).toUpperCase()}${str.slice(1)}`
const replaceLineToSpace = (str: string): string => str.toLowerCase().replace(' ', '-')
const replaceSpaceToLine = (str: string): string => str.replace('-', ' ')
const capitalizeString = (str: string): string =>
  str
    .split(' ')
    .map((word: string): string => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

const getNavigationIcon = (navigationTitle: string, hoveredItem: string | null): JSX.Element | null => {
  switch (true) {
    case navigationTitle === sidebarMenuLabels.TEMPLATES ||
      (navigationTitle === sidebarMenuLabels.TYPES && hoveredItem !== navigationTitle):
      return ServiceSVG.getTemplateIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.TEMPLATES ||
      (navigationTitle === sidebarMenuLabels.TYPES && hoveredItem === navigationTitle):
      return ServiceSVG.getTemplateIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.HOME && hoveredItem !== navigationTitle:
      return ServiceSVG.getHomeIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.HOME && hoveredItem === navigationTitle:
      return ServiceSVG.getHomeIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.SITES && hoveredItem !== navigationTitle:
      return ServiceSVG.getSitesIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.SITES && hoveredItem === navigationTitle:
      return ServiceSVG.getSitesIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.LEASES && hoveredItem !== navigationTitle:
      return ServiceSVG.getLeasesIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.LEASES && hoveredItem === navigationTitle:
      return ServiceSVG.getLeasesIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.PROMOTIONS && hoveredItem !== navigationTitle:
      return ServiceSVG.getPromotionsIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.PROMOTIONS && hoveredItem === navigationTitle:
      return ServiceSVG.getPromotionsIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.ROOMS && hoveredItem !== navigationTitle:
      return ServiceSVG.getRoomsIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.ROOMS && hoveredItem === navigationTitle:
      return ServiceSVG.getRoomsIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.HARDWARE && hoveredItem !== navigationTitle:
      return ServiceSVG.getHardwareIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.HARDWARE && hoveredItem === navigationTitle:
      return ServiceSVG.getHardwareIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.OPTIONS && hoveredItem !== navigationTitle:
      return ServiceSVG.getOptionsIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.OPTIONS && hoveredItem === navigationTitle:
      return ServiceSVG.getOptionsIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.USERS && hoveredItem !== navigationTitle:
      return ServiceSVG.getUsersIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.USERS && hoveredItem === navigationTitle:
      return ServiceSVG.getUsersIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.STAFF && hoveredItem !== navigationTitle:
      return ServiceSVG.getStaffIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.STAFF && hoveredItem === navigationTitle:
      return ServiceSVG.getStaffIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.SUPPORT && hoveredItem !== navigationTitle:
      return ServiceSVG.getSupportIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.SUPPORT && hoveredItem === navigationTitle:
      return ServiceSVG.getSupportIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.FAQ && hoveredItem !== navigationTitle:
      return ServiceSVG.getFaqIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.FAQ && hoveredItem === navigationTitle:
      return ServiceSVG.getFaqIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.TERMS && hoveredItem !== navigationTitle:
      return ServiceSVG.getTermsIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.TERMS && hoveredItem === navigationTitle:
      return ServiceSVG.getTermsIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.LEVELS && hoveredItem !== navigationTitle:
      return ServiceSVG.getLevelsIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.LEVELS && hoveredItem === navigationTitle:
      return ServiceSVG.getLevelsIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.ACCESS && hoveredItem !== navigationTitle:
      return ServiceSVG.getAccessIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.ACCESS && hoveredItem === navigationTitle:
      return ServiceSVG.getAccessIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.MEDIA && hoveredItem !== navigationTitle:
      return ServiceSVG.getMediaIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.MEDIA && hoveredItem === navigationTitle:
      return ServiceSVG.getMediaIcon('#7D5B51')
    case navigationTitle === sidebarMenuLabels.TRANSLATE && hoveredItem !== navigationTitle:
      return ServiceSVG.getTranslateIcon('#63382C')
    case navigationTitle === sidebarMenuLabels.TRANSLATE && hoveredItem === navigationTitle:
      return ServiceSVG.getTranslateIcon('#7D5B51')
    default:
      return null
  }
}

const replaceUrlSpace = (urlParam: string) => {
  return urlParam.replace(/\s+/g, '-')
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
const niceBytes = (x: any) => {
  let l = 0,
    n = parseInt(x, 10) || 0
  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + units[l]
}

const beforeUplaod = (file: RcFile): string | boolean => {
  const acceptedUploadType = [
    IMAGE_UPLOAD_FILE_TYPES.JPG_FILE,
    IMAGE_UPLOAD_FILE_TYPES.PNG_FILE,
    IMAGE_UPLOAD_FILE_TYPES.GIF_FILE,
  ]
  if (!acceptedUploadType.includes(file.type as string)) {
    return 'Selected file type is not supported.'
  }
  if (fileSizeInMb(file.size as number) > 10) {
    return 'Selected file size is more than 10MB.'
  }
  return true
}

function timeConverter(UNIX_timestamp: number) {
  var a = new Date(UNIX_timestamp)
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  var year = a.getUTCFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var time = date + ', ' + month + ' ' + year
  return time
}

export const formatCurrency = (value: number | null | undefined) => {
  const num = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  if (!value) return num.format(0)
  return num.format(value)
}

export const formatNumber = (value: number | null | undefined) => {
  const num = new Intl.NumberFormat('en-US')
  if (!value) return 0
  return num.format(value)
}

function getImageDetails(imageUrl: string | undefined) {
  if (imageUrl && imageUrl !== '') {
    const file = new File(['blob'], imageUrl)
    const imgName = file.name.split('/')[file.name.split('/').length - 1]
    return {
      url: file.name,
      name: imgName,
      created_at: UtilService.timeConverter(file.lastModified),
      size: UtilService.niceBytes(file.size),
    }
  }
  return {}
}

function getDay(dayIndex: number) {
  return DaysOfWeek[dayIndex] || ''
}

function getMaxTimeValue(building_hours: IBuildingHour[]) {
  let time = ''
  building_hours.forEach((item) => {
    if (item.end_time > time) time = item.end_time
  })
  return time
}

function getMinTimeValue(building_hours: IBuildingHour[]) {
  let time = ''
  building_hours.forEach((item) => {
    if (item.start_time < time || time === '') {
      if (item.start_time !== '') {
        time = item.start_time
      }
    }
  })

  return time
}

function getCleanIntervalHours(building_hours: IBuildingHour[]) {
  const fromHours = Hours.filter((obj) => {
    return obj.value >= UtilService.getMaxTimeValue(building_hours)
  })
  const toHours = Hours.filter((obj) => {
    return obj.value < UtilService.getMinTimeValue(building_hours)
  })
  return [...fromHours, ...toHours]
}

function dateToYMD(date: Date) {
  var d = date.getDate()
  var m = date.getMonth() + 1 //Month from 0 to 11
  var y = date.getFullYear()
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
}

function combineFilter(list: any) {
  const filteredItems = list?.filter((item: any) => item.category === 2)

  const options = filteredItems?.map((item: any) => ({
    value: item.id ?? item.value ?? item.code ?? '',
    name: item.name ?? item.label ?? '',
  }))

  return options
}

function optionsNameValue(list: any) {
  const options = list?.map((item: any) => {
    return {
      value: item?.id
        ? item?.id
        : item?.value === 0
        ? 0
        : item?.value
        ? item?.value
        : item?.code === 0
        ? 0
        : item?.code
        ? item?.code
        : '',
      name: item?.name ? item?.name : item?.label ? item?.label : '',
    }
  })
  return options
}

export const isNumeric = (value?: string | number): boolean => {
  return /^-?\d+$/.test(String(value))
}

export const fileSizeInMb = (size: number): number => Number((size / (1024 * 1024)).toFixed(2))

export const UIHourFormat = 'hh:mm A'
export const BEHourFormat = 'HH:mm'

export const formatSiteDateTime = (date: string | Date | Dayjs, includeTime: boolean = true) => {
  let formatString = 'ddd, MMM D, YYYY'
  if (includeTime) {
    formatString += ' h:mm A'
  }
  return dayjs(date).format(formatString)
}

export const numberFormat = (value: number) => {
  return Number(value).toFixed(2)
}

export const convertTime12HFormat = (time: string | undefined) => {
  if (time) return dayjs(time, BEHourFormat).format(UIHourFormat)
}

export const convertTime24HFormat = (time: string | Dayjs) => {
  return dayjs(time, UIHourFormat).format(BEHourFormat)
}

export const getInitials = (name: any | string) => {
  if (name)
    return name
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase()
  return ''
}

export const stringFirstLetters = (str: string) => {
  return str
    .split(' ')
    .map((word) => word[0])
    .join('')
}

export const downloadFile = async (blobPartOrUrl: BlobPart | string, type: string, name: string) => {
  try {
    let blob: Blob

    if (typeof blobPartOrUrl === 'string') {
      const response = await fetch(blobPartOrUrl)
      const data = await response.blob()
      blob = new Blob([data], { type })
    } else if (blobPartOrUrl instanceof Blob) {
      blob = blobPartOrUrl
    } else {
      blob = new Blob([blobPartOrUrl], { type })
    }

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link?.parentNode?.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error:', error)
  }
}

export const formatHourUI = (hour: string) => {
  return dayjs(hour, 'hh:mm:ss').format(UIHourFormat)
}

export const partition = <T>(array: T[] = [], filter: (value: T, index: number, array: T[]) => boolean): [T[], T[]] => {
  const pass: T[] = []
  const fail: T[] = []
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e))
  return [pass, fail]
}

export function selectify<T>(
  arr: T[] | undefined | null,
  label: keyof T = 'label' as keyof T,
  value: keyof T = 'value' as keyof T,
) {
  if (!arr) return []
  return arr.map((item) => ({
    label: item[label],
    value: item[value],
  }))
}

export const UtilService = {
  capitalizeFirstLetter,
  replaceLineToSpace,
  replaceSpaceToLine,
  capitalizeString,
  getNavigationIcon,
  replaceUrlSpace,
  niceBytes,
  timeConverter,
  getImageDetails,
  getDay,
  getMaxTimeValue,
  getMinTimeValue,
  getCleanIntervalHours,
  dateToYMD,
  combineFilter,
  optionsNameValue,
  fileSizeInMb,
  isNumeric,
  getInitials,
  beforeUplaod,
  downloadFile,
  formatHourUI,
}
